/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
body {
  background-color: #eee;
}

.card.card-cascade .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card.card-cascade h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}

.blue-gradient {
  background: -webkit-linear-gradient(50deg, #45cafc, #303f9f);
  background: linear-gradient(40deg, #45cafc, #4285f4);
}

.sidebar-fixed {
  height: 100vh;
  width: 270px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 0 1.5rem 1.5rem;
}

.sidebar-fixed .list-group .active {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.sidebar-fixed .logo-wrapper {
  padding: 2.5rem;
}

.sidebar-fixed .logo-wrapper img {
  max-height: 50px;
}

@media (min-width: 1200px) {
  .navbar, .page-footer, main {
    padding-left: 270px;
  }
}
@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
}